<template>
    <div class="flex flex-col mx-4 h-screen">    
        <div class="flex-auto">
            <img class="md:mx-20 my-4 h-28 md:h-32" :src="require('../../assets/logo.png')" />
        </div>
        <div class="flex-auto justify-center">
            <h3 class="text-gray-800 text-center font-semibold text-2xl md:text-4xl mb-12">
                Você quer testar como <span class="text-terciary">Consultor</span> ou como <span class="text-terciary">Empresa</span> ?
            </h3>
            <div class="flex justify-center">
                <div class="mx-4 md:mx-24">
                    <img class="h-28 md:h-32 mb-5" :src="require('../../assets/facillgpd-consultor.png')" alt="Facil LGPD Consultoria" />
                    <label for="consultoria" class="block text-base font-medium  text-center">
                        <input @change="empresa = false" type="checkbox" v-model="consultoria" class="rounded-sm" id="consultoria">
                        <span class="ml-2">
                            Consultoria
                        </span>
                    </label>
                </div>
                <div class="mx-4 md:mx-24">
                    <img class="h-28 md:h-32 mb-3" :src="require('../../assets/facillgpd-empresa.png')" alt="Facil LGPD Empresa" />
                    <label for="empresa" class="block text-base font-medium text-center">
                        <input @change="consultoria = false" type="checkbox" v-model="empresa" class="rounded-sm" id="empresa">
                        <span class="ml-2">
                            Empresa
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="flex-auto items-end">
            <div class="float-left">
                <progress-bar class="md:ml-10" :options="options" :value="value"/> 
                <label class="md:ml-10 block text-base font-medium text-center">
                    Passo 1 de 4
                </label>
            </div>
            <button @click="next" type="button" class="float-right md:mr-10 transition duration-200 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white px-8 py-4 text-sm md:text-base shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Continuar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consultoria: false, 
            empresa: false,
            value: 25,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: false,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#FBBF24',
                    backgroundColor: '#CCCC',
                    inverted: false
                },
                layout: {
                    height: 30,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },
    methods: {
        next () {
            if(!this.consultoria && !this.empresa) return this.$vToastify.error("Define como deseja registrar sua conta");
            this.$router.push({path:`/cadastrar?${this.empresa ? 'empresa': 'consultoria'}=true${this.$route.query && this.$route.query.codigo ? `&codigo=${this.$route.query.codigo}` : ''  }`})
        }
    },
    beforeMount() {
        if(this.$store.state.user && this.$store.state.user._id) this.$router.push({path:"/"})
    },
}
</script>